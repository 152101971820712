import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

export function DefaultButton({text, onPressed}) {

  return (
    <TouchableOpacity style={[styles.button]} onPress={() => onPressed()}>
      <Text style={styles.button__text}>{text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#FFF',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
  },

    button__hover: {
      backgroundColor: 'red'
    },


  button__text: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },

    
  
});