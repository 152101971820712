import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView } from "react-native";
import { EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { DataTable, TextInput } from "react-native-paper";
import { useGetOpenTicketsByAssetQuery, useGetTicketsByAssetQuery } from "../../../../store/services/api/ticket-api";

export default function OpenTicketsScreen({ navigation }: { navigation: any }) {
    const asset = useSelector((state: RootState) => state.asset);

    const {
        data: tickets,
    } = useGetOpenTicketsByAssetQuery(asset.id);

    return (
        <SafeAreaView style={GlobalStyle.mobile__page__container}>
            <View style={styles.header}>
                <TouchableOpacity
                    style={GlobalStyle.sub__title__icon}
                    onPress={() =>
                        navigation.goBack()
                    }
                >
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
            </View>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <View style={styles.section}>
                    {(tickets && tickets.length > 0) && <Text style={styles.title}>Open Tickets</Text>}
                </View>
                <View style={styles.section}>
                    {(tickets && tickets.length > 0) ? (
                        <View style={styles.section}>
                            {tickets.map(ticket => (
                                <DataTable key={ticket.id} style={{ width: "97%", alignSelf: 'center', marginBottom: "10%" }}>
                                    <DataTable.Row style={{ borderBottomWidth: 0 }}>
                                        <DataTable.Cell style={styles.titleCell}><Text style={styles.titleCellText}>Ticket No.</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.dataCell}><Text style={styles.dataCellText}>{ticket.number}</Text></DataTable.Cell>
                                    </DataTable.Row>
                                    <DataTable.Row style={{ borderBottomWidth: 0 }}>
                                        <DataTable.Cell style={styles.titleCell}><Text style={styles.titleCellText}>Issue Description</Text></DataTable.Cell>
                                        <DataTable.Cell style={styles.dataCell}><Text style={styles.dataCellText}>{ticket.description}</Text></DataTable.Cell>
                                    </DataTable.Row>
                                    <DataTable.Row style={{ borderBottomWidth: 0 }}>
                                        <DataTable.Cell style={[styles.titleCell, { borderBottomWidth: 2 }]}><Text style={styles.titleCellText}>Latest Update</Text></DataTable.Cell>
                                        <DataTable.Cell style={[styles.dataCell, { borderBottomWidth: 1 }]}><Text style={styles.dataCellText}>{ticket.latestUpdate || "No updates"}</Text></DataTable.Cell>
                                    </DataTable.Row>
                                </DataTable>
                            ))}
                        </View>
                    ) : (
                        <Text style={styles.title}>No tickets loaded</Text>
                    )}
                </View>
            </ScrollView>
        </SafeAreaView >
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
        alignSelf: "stretch",
        marginRight: 20,
        paddingLeft: 10,
    },
    title: {
        marginVertical: 20,
        textAlign: "center",
        paddingHorizontal: "5%",
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginBottom: "10%"
    },
    section: {
        width: "100%",
        height: "auto",
        alignItems: "stretch",
    },
    greyed__out__input: {
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 5,
        marginBottom: 10,
        backgroundColor: "#f2f2f2",
        width: "90%",
        alignSelf: 'center'
    },
    titleCell: {
        flex: 1,
        fontWeight: 'bold',
        borderWidth: 2,
        borderColor: 'black',
        borderBottomWidth: 0,
        justifyContent: 'center'
    },
    dataCell: {
        flex: 2,
        borderWidth: 1,
        borderColor: 'black',
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    titleCellText: {
        fontSize: 18,
        marginVertical: 10,
        textAlign: "center",
        paddingHorizontal: "5%",
        fontWeight: 'bold'
    },
    dataCellText: {
        fontSize: 18,
        marginVertical: 10,
        textAlign: "left",
        paddingHorizontal: "5%",
    },

});