import React, { useState } from "react";
import { Modal, View, StyleSheet, Text } from "react-native";
import {
    TextInput,
    Button,
    Provider as PaperProvider,
} from "react-native-paper";
import { useAddUpdateMutation } from "../../../../store/services/api/ticket-api";

const theme = {
    roundness: 10,
    colors: {
        primary: "#2C89C6",
        accent: "#2C89C6",
        text: "white",
        surface: "white",
        background: "white",
        onSurface: "#000",
    },
};

export default function TicketUpdateModal({ modalState }) {
    const { isUpdateModalVisible, setIsUpdateModalVisible, ticketToUpdate } = modalState;
    const [updateText, setUpdateText] = useState("");
    const [addUpdate, addUpdateResult] = useAddUpdateMutation();
    const [error, setError] = useState(null);

    const clearError = () => setError(null);

    const handleSave =  async () => {
        var result = null;

        if (!updateText) {
            setError("Update can't be empty");
            return;
        }

        result = await addUpdate({text: updateText, ticketId: ticketToUpdate.id}) as any;
        if (result.error) {
            return;
        };
        setIsUpdateModalVisible(false);
        setUpdateText("");
    };

    return (
        <PaperProvider theme={theme}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={isUpdateModalVisible}
                onRequestClose={() => setIsUpdateModalVisible(!isUpdateModalVisible)}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <TextInput
                            mode="outlined"
                            label="Update"
                            multiline={true}
                            numberOfLines={15}
                            onChangeText={text => { setUpdateText(text); clearError(); }}
                            value={updateText}
                            style={styles.textInput}
                        />
                        {error && <Text style={styles.errorText}>{error}</Text>}
                        <View style={styles.buttonGroup}>
                            <Button
                                mode="contained"
                                onPress={handleSave}
                                style={styles.saveButton}
                                labelStyle={styles.buttonLabel}
                            >
                                Save
                            </Button>
                            <Button
                                mode="contained"
                                onPress={() => {
                                    setIsUpdateModalVisible(false);
                                    setUpdateText("");
                                }}
                                style={styles.cancelButton}
                                labelStyle={styles.buttonLabel}
                                buttonColor="#D32F2F"
                            >
                                Cancel
                            </Button>
                        </View>
                    </View>
                </View>
            </Modal>
        </PaperProvider>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: theme.roundness,
        padding: 20,
        alignItems: "stretch",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 400,
        height: 450,
    },
    textInput: {
        marginBottom: 20,
        backgroundColor: "white",
    },
    buttonGroup: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    saveButton: {
        flex: 1,
        margin: 4,
    },
    cancelButton: {
        flex: 1,
        margin: 4,
        backgroundColor: "#D32F2F",
    },
    buttonLabel: {
        color: "white",
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
        textAlign: 'center'
    }
});