import { AntDesign, FontAwesome5, EvilIcons } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView } from "react-native";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

export default function AssetOptionsScreen({ navigation }: { navigation: any }) {

  const asset = useSelector((state: RootState) => state.asset);

  return (
    <SafeAreaView style={GlobalStyle.mobile__page__container}>
      <View style={styles.header}>
        <TouchableOpacity
          style={GlobalStyle.sub__title__icon}
          onPress={() =>
            navigation.navigate("asset", { uniqueRef: asset.uniqueReference })
          }
        >
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={{ width: "80%" }}>

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("asset-details-screen");
            }}
            style={styles.button}
          >
            <FontAwesome5 name="info" size={32} color="white" style={{ marginRight: 10 }} />
            <Text style={styles.buttonText}>View Details</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ticket-screen', { screen: 'ticket-status-screen' });
            }}
            style={styles.button}
          >
            <AntDesign name="question" size={40} color="white" />
            <Text style={styles.buttonText}>Check status</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView >
  );
}

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    marginTop: 20,
    alignSelf: "flex-start",
    marginRight: 20,
    marginBottom: 10,
    padding: 20,
  },
  title: {
    fontSize: 18,
    marginVertical: 5,
    textAlign: "center",
    paddingHorizontal: "5%",
  },
  buttonContainer: {
    marginVertical: 10,
    width: "80%",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#2C89C6",
    paddingVertical: 10,
    paddingHorizontal: "10%",
    marginVertical: 10,
    borderRadius: 5,
    width: "100%",
    justifyContent: "center",
  },
  buttonText: {
    color: "white",
    marginLeft: 10,
    fontSize: 20
  },
  footer: {
    fontSize: 16,
    color: "#3B3B3B",
    marginBottom: 20,
  },
  ratingContainer: {
    flex: 3,
    alignSelf: 'stretch',
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    marginVertical: 5,
  },
});
