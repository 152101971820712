import { baseApi, PagedTableDto } from './base-api'

export const buildingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBuildings: build.query<any, any>({
      query: (dto) => ({
        url: `buildings/getallbuildings`,
      })
    }),
    getAllFloors: build.query<any, any>({
      query: (dto) => ({
        url: `buildings/getallfloors`,
      })
    }),
    getAllLocations: build.query<any, any>({
      query: (dto) => ({
        url: `buildings/getalllocations`,
      })
    }),
    getBuildings: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `buildings/getbuildings?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    deleteBuilding: build.mutation<any, any>({
      query: (id) => ({
        url: `buildings/deleteBuilding/${id}`,
        method: 'delete',
      }),
    }),
    createBuilding: build.mutation<any, any>({
      query: (body) => ({
        url: 'buildings/addBuilding',
        method: 'post',
        body: body
      }),
    }),
    updateBuilding: build.mutation<any, any>({
      query: (body) => ({
        url: `buildings/updateBuilding/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getBuilding: build.query<any, any>({
      query: (buildingId) => ({
        url: `buildings/getBuilding/${buildingId}`,
      }),
    }),
    getFloors: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `buildings/getFloors?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    deleteFloor: build.mutation<any, any>({
      query: (id) => ({
        url: `buildings/deleteFloor/${id}`,
        method: 'delete',
      }),
    }),
    createFloor: build.mutation<any, any>({
      query: (body) => ({
        url: 'buildings/addFloor',
        method: 'post',
        body: body
      }),
    }),
    updateFloor: build.mutation<any, any>({
      query: (body) => ({
        url: `buildings/updateFloor/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getFloor: build.query<any, any>({
      query: (buildingId) => ({
        url: `buildings/getFloor/${buildingId}`,
      }),
    }),
    getLocations: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `buildings/getLocations?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    deleteLocation: build.mutation<any, any>({
      query: (id) => ({
        url: `buildings/deleteLocation/${id}`,
        method: 'delete',
      }),
    }),
    createLocation: build.mutation<any, any>({
      query: (body) => ({
        url: 'buildings/addLocation',
        method: 'post',
        body: body
      }),
    }),
    updateLocation: build.mutation<any, any>({
      query: (body) => ({
        url: `buildings/updateLocation/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getLocation: build.query<any, any>({
      query: (buildingId) => ({
        url: `buildings/getLocation/${buildingId}`,
      }),
    }),
  })
})

export const {
  useGetAllBuildingsQuery,
  useGetAllFloorsQuery,
  useGetAllLocationsQuery,
  useCreateBuildingMutation,
  useDeleteBuildingMutation,
  useGetBuildingQuery,
  useGetBuildingsQuery,
  useUpdateBuildingMutation,
  useCreateFloorMutation,
  useDeleteFloorMutation,
  useGetFloorQuery,
  useGetFloorsQuery,
  useUpdateFloorMutation,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetLocationQuery,
  useGetLocationsQuery,
  useUpdateLocationMutation
} = buildingApi