import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView } from "react-native";
import { AntDesign, MaterialIcons, EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";

export default function TicketSubmitConfirmationScreen({ navigation }: { navigation: any; }) {
  const ticket = useSelector((state: RootState) => state.ticket);
  const asset = useSelector((state: RootState) => state.asset);

  return (
    <SafeAreaView style={GlobalStyle.mobile__page__container}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.header}>
          <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("asset", { uniqueRef: asset.uniqueReference })}>
            <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
          </TouchableOpacity>
        </View>
        <View style={styles.content}>
          <Text style={styles.text}>
            Thank you for submitting a ticket.
          </Text>
          <Text style={styles.text}>
            Your ticket number is {ticket.number}
          </Text>
          <Text style={styles.paragraph}></Text>
          <Text style={[styles.text, { marginLeft: "15%", marginRight: "15%" }]}>
            You can track updates for this ticket by selecting the 'Create ticket' button again, followed by 'View Status'
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    alignContent: "space-between",
    alignSelf: "stretch",
    marginRight: 20,
    marginBottom: 10,
    padding: 20,
  },
  content: {
    flex: 1,
    marginBottom: 20,
    justifyContent: 'center',
  },
  text: {
    textAlign: "center",
    marginBottom: 10,
    fontSize: 20
  },
  paragraph: {
    marginBottom: 20,
  },
  buttons__container: {
    width: "100%",
    alignItems: "center",
    marginBottom: 20
  },
  buttonText: {
    color: "white",
    marginLeft: 10,
  },
});
