import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AssetScreen from './mobile/screens/Asset/AssetScreen';
import TicketScreen from './mobile/screens/Ticket/TicketScreen';

const Stack = createNativeStackNavigator();

export default function MobileNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="asset-screen" component={AssetScreen} options={{ headerShown: false, title: 'Asset' }}/>
      <Stack.Screen name="ticket-screen" component={TicketScreen} options={{ headerShown: false, title: 'Asset' }}/>
    </Stack.Navigator>
  );
}
