import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AssetState {
  id: string | null;
  assetTypeId: string | null;
  buildingId: string | null;
  code: string | null;
  description: string | null;
  floorId: string | null;
  imageBase64: string | null;
  locationId: string | null;
  manufacturer: string | null;
  subAssetTypeId: string | null;
  uniqueReference: string | null;
  troubleshootingGuide: string | null;
}

const initialState: AssetState = {
  id: null,
  assetTypeId: null,
  buildingId: null,
  code: null,
  description: "",
  floorId: null,
  imageBase64: null,
  locationId: null,
  manufacturer: null,
  subAssetTypeId: null,
  uniqueReference: null,
  troubleshootingGuide: null
};

export const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    setAsset: (state, action: PayloadAction<any>) => {
      if (action.payload.id != null) state.id = action.payload.id;
      if (action.payload.assetTypeId != null)
        state.assetTypeId = action.payload.assetTypeId;
      if (action.payload.buildingId != null)
        state.buildingId = action.payload.buildingId;
      if (action.payload.code != null) state.code = action.payload.code;
      if (action.payload.description != null)
        state.description = action.payload.description;
      if (action.payload.floorId != null)
        state.floorId = action.payload.floorId;
      if (action.payload.imageBase64 != null)
        state.imageBase64 = action.payload.imageBase64;
      if (action.payload.locationId != null)
        state.locationId = action.payload.locationId;
      if (action.payload.manufacturer != null)
        state.manufacturer = action.payload.manufacturer;
      if (action.payload.subAssetTypeId != null)
        state.subAssetTypeId = action.payload.subAssetTypeId;
      if (action.payload.uniqueReference != null)
        state.uniqueReference = action.payload.uniqueReference;
      if (action.payload.subAssetTypeId != null)
        state.subAssetTypeId = action.payload.subAssetTypeId;
      if (action.payload.troubleshootingGuide != null)
        state.troubleshootingGuide = action.payload.troubleshootingGuide;
    },

    clearAsset: (state) => {
      state.id = null;
      state.buildingId = null;
      state.assetTypeId = null;
      state.code = null;
      state.description = null;
      state.floorId = null;
      state.imageBase64 = null;
      state.locationId = null;
      state.manufacturer = null;
      state.uniqueReference = null;
      state.subAssetTypeId = null;
      state.troubleshootingGuide = null;
    },
  },
});

export const { setAsset, clearAsset } = assetSlice.actions;

export default assetSlice.reducer;
