export class ServiceResponse {
    data: any;
    error: boolean = false;
  
    constructor() {
    }
  
    static throwError() : ServiceResponse {
      var response = new ServiceResponse();
      response.error = true;
      return response;
    }
  
    static success(data: any) : ServiceResponse {
      var response = new ServiceResponse();
      response.data = data;
      return response;
    }
  }