import React from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView, SafeAreaView, } from "react-native";
import { EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { TextInput } from "react-native-paper";

export default function AssetFeedbackConfirmationScreen({ navigation }: { navigation: any; }) {
    const asset = useSelector((state: RootState) => state.asset);

    return (
        <SafeAreaView style={GlobalStyle.mobile__page__container}>
            <View style={styles.header}>
                <TouchableOpacity
                    style={GlobalStyle.sub__title__icon}
                    onPress={() =>
                        navigation.navigate("asset", { uniqueRef: asset.uniqueReference })
                    }
                >
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
            </View>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <View style={styles.section}>
                    <TextInput
                        style={styles.greyed__out__input}
                        value={"Thank you for your feedback"}
                        editable={false}
                        multiline={true}
                    ></TextInput>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
        alignSelf: "stretch",
        marginRight: 20,
    },
    title: {
        fontSize: 18,
        marginVertical: 20,
        textAlign: "center",
        paddingHorizontal: "5%",
        alignSelf: "flex-start",
    },
    buttonContainer: {
        marginVertical: 20,
        width: "100%",
        alignItems: "center",
    },
    buttonText: {
        color: "white",
        marginLeft: 10,
        fontSize: 24
    },
    footer: {
        fontSize: 16,
        color: "#3B3B3B",
        marginBottom: 20,
    },
    section: {
        width: "90%",
        height: "auto",
        alignItems: "stretch",
    },
    input: {
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 5,
        marginBottom: 10,
        backgroundColor: "white",
        width: "100%",
    },
    greyed__out__input: {
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 5,
        padding: 5,
        marginBottom: 10,
        backgroundColor: "white",
        width: "100%",
        fontSize: 30,
        textAlign: 'center'
    },
    addButtonContainer: {
        alignSelf: "flex-start",
    },
    addButton: {
        marginRight: 10,
        backgroundColor: PRIMARY_COLOUR,
        padding: 8,
        borderRadius: 4,
        justifyContent: "center",
    },
    ratingContainer: {
        flexDirection: "row",
        alignSelf: 'center',
        justifyContent: "space-between",
        marginBottom: 20,
        width: '90%'
    },
});
