import { baseApi, PagedTableDto } from './base-api'

export const assetTypeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAssetTypes: build.query<any, any>({
      query: (dto) => ({
        url: `assetTypes/getallassettypes`,
      })
    }),
    getAllSubAssetTypes: build.query<any, any>({
      query: (dto) => ({
        url: `assetTypes/getallsubassettypes`,
      })
    }),
    getAssetTypes: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `assetTypes/getassetTypes?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    deleteAssetType: build.mutation<any, any>({
      query: (id) => ({
        url: `assetTypes/deleteAssetType/${id}`,
        method: 'delete',
      }),
    }),
    createAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: 'assetTypes/addAssetType',
        method: 'post',
        body: body
      }),
    }),
    updateAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: `assetTypes/updateAssetType/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getAssetType: build.query<any, any>({
      query: (assetTypeId) => ({
        url: `assetTypes/getAssetType/${assetTypeId}`,
      }),
    }),
    getSubAssetTypes: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `assetTypes/getSubAssetTypes?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    deleteSubAssetType: build.mutation<any, any>({
      query: (id) => ({
        url: `assetTypes/deleteSubAssetType/${id}`,
        method: 'delete',
      }),
    }),
    createSubAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: 'assetTypes/addSubAssetType',
        method: 'post',
        body: body
      }),
    }),
    updateSubAssetType: build.mutation<any, any>({
      query: (body) => ({
        url: `assetTypes/updateSubAssetType/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getSubAssetType: build.query<any, any>({
      query: (assetTypeId) => ({
        url: `assetTypes/getSubAssetType/${assetTypeId}`,
      }),
    }),
  })
})

export const {
  useGetAllAssetTypesQuery,
  useGetAllSubAssetTypesQuery,
  useGetAssetTypesQuery,
  useDeleteAssetTypeMutation,
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useGetAssetTypeQuery,
  useCreateSubAssetTypeMutation,
  useDeleteSubAssetTypeMutation,
  useGetSubAssetTypeQuery,
  useGetSubAssetTypesQuery,
  useUpdateSubAssetTypeMutation
} = assetTypeApi