import { useIsFocused } from "@react-navigation/core";
import React, { useEffect, useState } from "react";
import { ScrollView, View, StyleSheet, Text, TextInput, TouchableOpacity, Image } from "react-native";
import GlobalStyle from "../../../../constants/GlobalStyle";
import { Picker } from '@react-native-picker/picker';
import { Ionicons, SimpleLineIcons, Entypo } from "@expo/vector-icons";
import { DataTable } from 'react-native-paper';
import Spinner from 'react-native-loading-spinner-overlay';
import { assetApi } from "../../../../store/services/api/asset-api";
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { useGetAllAssetTypesQuery, useGetAllSubAssetTypesQuery } from "../../../../store/services/api/assetType-api";
import { useGetAllBuildingsQuery, useGetAllFloorsQuery } from "../../../../store/services/api/building-api";
import PaginationComponent from "../../components/PaginationComponent";
import { DataTableHeader } from "../../components/DataTableHeader";

export default function AssetsListScreen({ navigation }: { navigation: any }) {
    const isFocused = useIsFocused();
    const [loading, setLoading] = useState<any>(false);
    const [trigger, assetsResult] = assetApi.useLazyGetAssetsQuery();
    const [page, setPage] = React.useState<number>(0);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [sortedColumn, setSortedColumn] = React.useState<string>("uniqueReference");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

    const [buildingId, setBuildingId] = useState<any>("");
    const [floorId, setFloorId] = useState<any>("");
    const [assetTypeId, setAssetTypeId] = useState<any>("");
    const [subAssetTypeId, setSubAssetTypeId] = useState<any>("");
    const [description, setDescription] = useState<any>("");
    const [code, setCode] = useState<any>("");
    const [uniqueReference, setUniqueReference] = useState<any>("");

    const { data: buildings } = useGetAllBuildingsQuery(null);
    const { data: floors } = useGetAllFloorsQuery(null);
    const { data: assetTypes } = useGetAllAssetTypesQuery(null);
    const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

    useEffect(() => {
        loadTable();
    }, [page, isFocused, sortedColumn, sortedColumnAscending, numberOfItemsPerPage])

    const resetFilters = () => {
        setBuildingId("");
        setFloorId("");
        setAssetTypeId("");
        setSubAssetTypeId("");
        setDescription("");
        setCode("");
        setUniqueReference("");
    }

    const loadTable = () => {
        var dto = new PagedTableDto(page, {
            buildingId: buildingId,
            floorId: floorId,
            assetTypeId: assetTypeId,
            subAssetTypeId: subAssetTypeId,
            description: description,
            code: code,
            uniqueReference: uniqueReference,
            pageSize: numberOfItemsPerPage
        }, sortedColumn, sortedColumnAscending);
        trigger(dto).then(() => {
            setLoading(false);
        });
    }

    return (
        <ScrollView style={GlobalStyle.container}>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />

            <View style={GlobalStyle.filters}>
                <View style={GlobalStyle.filters__header}>
                    <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
                </View>

                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Building</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={buildingId}
                            onValueChange={(itemValue, itemIndex) => { setBuildingId(itemValue); setFloorId(""); }}>

                            <Picker.Item label="Please Select" value="" />

                            {buildings != null && buildings.map((value, index) => {
                                return (
                                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                                )
                            })}
                        </Picker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Floor</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={floorId}
                            onValueChange={(itemValue, itemIndex) => setFloorId(itemValue)}>

                            <Picker.Item label="Please Select" value="" />

                            {buildingId && floors.filter(f => f.buildingId === buildingId).map((value, index) => {
                                return (
                                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                                )
                            })}
                        </Picker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Asset Type</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={assetTypeId}
                            onValueChange={(itemValue, itemIndex) => { setAssetTypeId(itemValue); setSubAssetTypeId("") }}>

                            <Picker.Item label="Please Select" value="" />

                            {assetTypes && assetTypes.map((value, index) => {
                                return (
                                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                                )
                            })}
                        </Picker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Sub Asset Type</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={subAssetTypeId}
                            onValueChange={(itemValue, itemIndex) => setSubAssetTypeId(itemValue)}>

                            <Picker.Item label="Please Select" value="" />

                            {assetTypeId && subAssetTypes.filter(sa => sa.assetTypeId === assetTypeId).map((value, index) => {
                                return (
                                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                                )
                            })}
                        </Picker>
                    </View>
                </View>
                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Code</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setCode}
                            value={code}
                        />
                    </View>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Description</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setDescription}
                            value={description}
                        />
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>CoreRFID QR</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setUniqueReference}
                            value={uniqueReference}
                        />
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                                <SimpleLineIcons name="magnifier" size={16} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                                <SimpleLineIcons name="ban" size={16} color="black" />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
            <TouchableOpacity style={GlobalStyle.add__button} onPress={() => navigation.navigate("manage")}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 10 }}>
                    <Ionicons name="add" size={24} color="white" /><Text style={GlobalStyle.form__submit__button__text}>Add New Asset</Text>
                </View>
            </TouchableOpacity>

            <DataTable style={GlobalStyle.table}>
                <DataTableHeader
                    sortedColumn={sortedColumn}
                    sortedColumnAscending={sortedColumnAscending}
                    onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                    headers={[
                        { text: 'Building', sortedName: "buildingName", visible: true },
                        { text: 'Floor', sortedName: "floorName", visible: true },
                        { text: 'Specific Location', sortedName: "locationName", visible: true },
                        { text: 'Asset Type', sortedName: "assetTypeName", visible: true },
                        { text: 'Sub Asset Type', sortedName: "subAssetTypeName", visible: true },
                        { text: 'Description', sortedName: "description", visible: true },
                        { text: 'Code', sortedName: "code", visible: true },
                        { text: 'CoreRFID QR', sortedName: "uniqueReference", visible: true },
                        { text: 'Photo', visible: true },
                        { text: 'Options', visible: true },
                    ]}
                />

                {assetsResult.data?.list.map((element: any) => {
                    return (
                        <DataTable.Row key={element.id}>
                            <DataTable.Cell>{element.buildingName}</DataTable.Cell>
                            <DataTable.Cell>{element.floorName}</DataTable.Cell>
                            <DataTable.Cell>{element.locationName}</DataTable.Cell>
                            <DataTable.Cell>{element.assetTypeName}</DataTable.Cell>
                            <DataTable.Cell>{element.subAssetTypeName}</DataTable.Cell>
                            <DataTable.Cell>{element.description}</DataTable.Cell>
                            <DataTable.Cell>{element.code}</DataTable.Cell>
                            <DataTable.Cell>{element.uniqueReference}</DataTable.Cell>
                            <DataTable.Cell>
                                {element.imageUri != null &&
                                    <Image
                                        style={{ width: 50, height: 50, marginBottom: 5, marginTop: 5 }}
                                        resizeMode={"contain"}
                                        source={{
                                            uri: `${element.imageUri}?t=${new Date().getTime()}`
                                        }}
                                    />
                                }
                            </DataTable.Cell>
                            <DataTable.Cell>
                                <TouchableOpacity onPress={() => navigation.navigate("manage", { assetId: element.id })}>
                                    <Entypo name="pencil" size={24} color="black" />
                                </TouchableOpacity>
                            </DataTable.Cell>
                        </DataTable.Row>
                    )
                })}
            </DataTable>

            <PaginationComponent
                numberOfItemsPerPage={numberOfItemsPerPage}
                setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                page={page}
                setPage={setPage}
                fetchResult={assetsResult}
                setLoading={setLoading}
            />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    }

});