import React, { } from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView } from "react-native";
import { EvilIcons, AntDesign } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { TextInput } from "react-native-paper";

export default function AssetTroubleshootingScreen({ navigation }) {
    const asset = useSelector((state: RootState) => state.asset);

    return (
        <SafeAreaView style={GlobalStyle.mobile__page__container}>
            <View style={styles.header}>
                <TouchableOpacity
                    style={GlobalStyle.sub__title__icon}
                    onPress={() => navigation.goBack()}
                >
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
            </View>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <View style={styles.section}>
                    <Text style={styles.title}>Item Description</Text>
                    <TextInput
                        mode="outlined"
                        style={styles.input}
                        value={asset ? asset.description : "Loading..."}
                        disabled={true}
                    />
                </View>
                <ScrollView>
                    <View style={styles.section}>
                        <Text style={styles.title}>Troubleshooting</Text>
                        <TextInput
                            mode="outlined"
                            style={styles.input}
                            value={asset ? asset.troubleshootingGuide : "Loading..."}
                            disabled={true}
                            multiline
                            numberOfLines={15}
                        />
                    </View>
                </ScrollView>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
        justifyContent: 'center',
        width: "100%",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 20,
        width: "100%",
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
    },
    input: {
        flex: 1,
        backgroundColor: "#f2f2f2",
    },
    section: {
        width: "95%",
        height: "auto",
        alignItems: "stretch",
        alignSelf: 'center',
        marginBottom: 20,
        paddingHorizontal: "5%",
    },
    multiLineCell: {
        flex: 1,
        justifyContent: "flex-start",
    },
});