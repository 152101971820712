import React, { } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import GlobalStyle from "../../../../constants/GlobalStyle";

export const AssetTypeManageControl = ({ assetType, onSubmit }) => {

    const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: "",
        }
    });
    let name = watch("name");

    React.useEffect(() => {
        if (assetType != null) {
            setValue("id", assetType.id);
            setValue("name", assetType.name);
        }
    }, [assetType])

    const submit = async (data) => {
        onSubmit(data);
    }

    return (
        <ScrollView>
            <View style={GlobalStyle.form}>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="name"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Name (*)</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                </View>
            </View>
            <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
                <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
            </TouchableOpacity>
        </ScrollView>
    )
};

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    form__rows: {
        marginRight: 5,
        flexDirection: "row",
        alignItems: "center"
    },

    form__label: {
        fontFamily: 'OpenSans-Light',
        fontWeight: 'bold',
        paddingTop: 10,
        fontSize: 14,
        flex: 1
    },

    form__input: {
        flex: 2,
        fontFamily: 'OpenSans-Regular',
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CECECE',
        marginTop: 5,
    },

});
