import React, { useState } from "react";
import { Entypo, Ionicons } from "@expo/vector-icons";
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { useForm, Controller } from "react-hook-form";
import { View, TouchableOpacity, TextInput, Text, StyleSheet } from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { DataTable } from "react-native-paper";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { DataTableHeader } from "../../components/DataTableHeader";
import PaginationComponent from "../../components/PaginationComponent";
import { buildingApi, useCreateLocationMutation, useDeleteLocationMutation, useUpdateLocationMutation } from "../../../../store/services/api/building-api";

export function LocationsList({ floor }) {
    const [page, setPage] = useState<number>(0);
    const [sortedColumn, setSortedColumn] = React.useState<string>("name");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [loading, setLoading] = useState<any>(false);

    const [trigger, locationsResult] = buildingApi.useLazyGetLocationsQuery();
    const [createLocation, createLocationResult] = useCreateLocationMutation();
    const [updateLocation, updateLocationResult] = useUpdateLocationMutation();
    const [deleteLocation, deleteLocationResult] = useDeleteLocationMutation();

    const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            floorId: floor?.id,
            name: "",
        }
    });

    const id = watch("id");

    React.useEffect(() => {
        loadTable();
    }, [page, floor, sortedColumn, sortedColumnAscending]);

    const loadTable = () => {
        trigger(new PagedTableDto(page, {
            floorId: floor?.id,
            pageSize: numberOfItemsPerPage
        }, sortedColumn, sortedColumnAscending)).then(() => {
            setLoading(false);
        });
    }

    const onSubmit = async (data) => {
        data.floorId = floor.id;

        var result = null;
        if (data.id != null) {
            result = await updateLocation(data) as any;
        } else {
            result = await createLocation(data) as any;
        }

        if (result.error) {
            alert(result.error)
            return;
        }

        reset();
        loadTable();
    }


    const edit = (location) => {
        setValue("id", location.id);
        setValue("floorId", location.floorId);
        setValue("name", location.name);
    }

    const remove = async (location) => {
        if (confirm("Are you sure you want to delete this location?")) {
            if (location.assetCount !== 0) {
                alert(`Can't delete location, there are ${location.assetCount} assets associated with it.`)
                return;
            }
            var result = await deleteLocation(location.id) as any;

            if (result.error) {
                alert("Error deleting location. Please try again.")
                return;
            }

            loadTable();
        }
    }

    return (
        <View style={{ flex: 1 }}>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />
            <View>
                <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>{floor != null ? floor.name + ' - ' : null} Floor Locations</Text>
                {floor != null &&
                    <View style={[GlobalStyle.form, styles.form]}>
                        <View style={GlobalStyle.form__row}>
                            <View style={[GlobalStyle.form__column, { maxWidth: '60%' }]}>
                                <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name="name"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <View>
                                            <Text style={GlobalStyle.form__column__text}>Name (*)</Text>
                                            <TextInput
                                                style={GlobalStyle.form__column__input}
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}>
                                            </TextInput>
                                        </View>
                                    )}
                                />

                                {errors.name && <Text style={GlobalStyle.form__input__validation}>Response is required.</Text>}
                            </View>
                            <View style={styles.form__buttons}>
                                <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                                    <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                                </TouchableOpacity>

                                {id != null &&
                                    <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                                        <Text style={styles.form__add__btn__text}>Clear</Text>
                                    </TouchableOpacity>
                                }
                            </View>

                        </View>
                    </View>
                }
            </View>

            {floor == null &&
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontSize: 28, fontStyle: 'italic' }}>Select Floor</Text>
                </View>
            }

            {floor !== null &&
                <View>
                    <DataTable style={GlobalStyle.table}>
                        <DataTableHeader
                            sortedColumn={sortedColumn}
                            sortedColumnAscending={sortedColumnAscending}
                            onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                            headers={[
                                { text: 'Name', sortedName: "name", visible: true },
                                { text: 'Actions', visible: true },
                            ]}
                        />

                        {locationsResult?.data?.list.map((element: any) => {
                            return (
                                <DataTable.Row key={element.id}>
                                    <DataTable.Cell>{element.name}</DataTable.Cell>
                                    <DataTable.Cell>
                                        <View style={styles.table__actions}>
                                            <TouchableOpacity style={styles.action} onPress={() => edit(element)}><Entypo name="pencil" size={24} color="black" /></TouchableOpacity>
                                            <TouchableOpacity style={styles.action} onPress={() => remove(element)}><Ionicons name="ios-remove-circle" size={24} color="red" /></TouchableOpacity>
                                        </View>
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )
                        })}
                    </DataTable>

                    <PaginationComponent
                        numberOfItemsPerPage={numberOfItemsPerPage}
                        setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                        page={page}
                        setPage={setPage}
                        fetchResult={locationsResult}
                        setLoading={setLoading}
                    />
                </View>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },

    form: {
        borderBottomWidth: 1,
        borderColor: '#CECECE',
        paddingBottom: 20,
        marginBottom: 10
    },

    form__buttons: {
        width: 150,
        flexDirection: 'row',
    },

    form__add__btn: {
        backgroundColor: PRIMARY_COLOUR,
        flex: 1,
        height: 31,
        alignSelf: 'flex-end',
        textAlign: 'center',
        marginLeft: 10,
        marginBottom: 5,

        alignItems: 'center',
        justifyContent: 'center',
    },

    form__add__btn__text: {
        color: '#FFF',
    },

    table__actions: {
        flexDirection: 'row',
    },

    action: {
        paddingLeft: 5,
        paddingRight: 5,
    },

    add__new__link: {
        borderBottomColor: '#CECECE',
        borderBottomWidth: 1,
        paddingBottom: 10,
        marginBottom: 0,
    },

});
