import { useState } from "react";
import { View, StyleSheet } from "react-native";
import BuildingsListScreen from "./BuildingsListScreen";
import { FloorsList } from "./FloorsList";
import { LocationsList } from "./LocationsList";
import { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";

export default function ServiceQuestions({ navigation }: { navigation: any }) {
    const [selectedBuilding, setSelectedBuilding] = useState<any>(null);
    const [selectedFloor, setSelectedFloor] = useState<any>(null);

    return (
        <View style={styles.service__content}>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <View style={styles.service__block}>
                    <BuildingsListScreen
                        onSelected={(building) => setSelectedBuilding(building)} navigation={undefined} />
                </View>
                {selectedBuilding && <View style={styles.service__block}>
                    <FloorsList building={selectedBuilding} onSelected={(floor) => setSelectedFloor(floor)} />
                </View>}
                {selectedFloor && <View style={styles.service__block}>
                    <LocationsList floor={selectedFloor} />
                </View>}
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    service__content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10
    },

    service__block: {
        flex: 1,
        padding: 10,
        backgroundColor: '#FFF',
        margin: 10,
        borderWidth: 1,
        borderColor: '#CECECE'
    },

    add__new__link: {
        borderBottomColor: '#CECECE',
        borderBottomWidth: 1,
        paddingBottom: 10,
        marginBottom: 0,
    },

    form: {
        borderBottomWidth: 1,
        borderColor: '#CECECE',
        paddingBottom: 20,
        marginBottom: 10
    },

    form__buttons: {
        width: 150,
        flexDirection: 'row',
        alignItems: 'center'
    },

    form__add__btn: {
        backgroundColor: PRIMARY_COLOUR,
        flex: 1,
        height: 31,
        alignSelf: 'flex-end',
        textAlign: 'center',
        marginLeft: 10,
        marginBottom: 5,

        alignItems: 'center',
        justifyContent: 'center',
    },

    form__add__btn__text: {
        color: '#FFF',
    },

    table__actions: {
        flexDirection: 'row',
    },

    action: {
        paddingLeft: 5,
        paddingRight: 5,
    },

});
