import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView, TextInput } from "react-native";
import { AntDesign, EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { Controller, useForm } from "react-hook-form";
import { useUpdateTicketEmailMutation } from "../../../../store/services/api/ticket-api";

export default function UpdateTicketScreen({ navigation }: { navigation: any; }) {
    const ticket = useSelector((state: RootState) => state.ticket);
    const [updateTicketEmail, updateTicketEmailResult] = useUpdateTicketEmailMutation();

    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            id: ticket?.id,
            contactEmail: ticket?.contactEmail || "",
        }
    });

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const onSubmit = async (data) => {
        const result = await updateTicketEmail({ id: data.id, contactEmail: data.contactEmail }) as any;

        if (result.error) {
            console.error(result.error);
            return;
        } else {
            navigation.goBack();
        }
    }

    return (
        <SafeAreaView style={GlobalStyle.mobile__page__container}>
            <View style={styles.header}>
                <TouchableOpacity
                    style={GlobalStyle.sub__title__icon}
                    onPress={() => navigation.goBack()}
                >
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
            </View>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <View style={styles.section}>
                    <Controller
                        control={control}
                        rules={{ required: true, validate: isValidEmail }}
                        name="contactEmail"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <View>
                                <Text style={styles.label}>Update Contact email</Text>
                                <TextInput
                                    style={styles.input}
                                    onBlur={onBlur}
                                    onChangeText={onChange}
                                    value={value}
                                />
                            </View>
                        )}
                    />
                    {errors.contactEmail && <Text style={GlobalStyle.form__input__validation}>Email is not valid.</Text>}
                </View>
                <View style={styles.button__container}>
                    <TouchableOpacity style={GlobalStyle.mobile__button} onPress={handleSubmit(onSubmit)}>
                        <Text style={styles.button__text}>Submit change</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
        alignSelf: "stretch",
        marginRight: 20,
    },
    content: {
        alignItems: "center",
        marginBottom: 20,
    },
    section: {
        width: "80%",
        height: "auto",
        alignItems: "stretch"
    },
    input: {
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 5,
        marginBottom: 10,
        backgroundColor: "white",
        width: "100%",
        padding: 10
    },
    label: {
        marginBottom: 5,
    },
    button__container: {
        marginVertical: 20,
        width: "90%",
        alignItems: "center",
    },
    button__text: {
        color: "white",
        fontSize: 20,
    },
});
