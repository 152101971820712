import * as ImageManipulator from 'expo-image-manipulator';

export enum PlatformType {
  Web = 'web',
  Mobile = 'mobile'
}

const compressionConfig = {
  [PlatformType.Web]: { compress: 0.5 },
  [PlatformType.Mobile]: { compress: 0.2 }
};

export const compressImage = async (base64: string, platform: PlatformType = PlatformType.Mobile) => {
  const { compress } = compressionConfig[platform];
  const compressedImage = await ImageManipulator.manipulateAsync(
    base64,
    [],
    { compress, format: ImageManipulator.SaveFormat.JPEG, base64: true }
  );

  return compressedImage.uri;
};
