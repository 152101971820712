import { SimpleLineIcons } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import React, { } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Dimensions, ScrollView, Image } from 'react-native';
import GlobalStyle from "../../../../constants/GlobalStyle";
import * as DocumentPicker from 'expo-document-picker';

export const AssetManageControl = ({ buildings, floors, locations, assetTypes, subAssetTypes, asset, onSubmit }) => {

    const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            buildingId: "",
            floorId: "",
            locationId: "",
            assetTypeId: "",
            subAssetTypeId: "",
            description: "",
            code: "",
            uniqueReference: "",
            imageBase64: "",
            troubleshootingGuide: ""
        }
    });
    let buildingId = watch("buildingId");
    let floorId = watch("floorId");
    let assetTypeId = watch("assetTypeId");
    let imageBase64 = watch("imageBase64");

    React.useEffect(() => {
        if (asset != null) {
            setValue("id", asset.id);
            setValue("buildingId", asset.buildingId);
            setValue("floorId", asset.floorId);
            setValue("locationId", asset.locationId);
            setValue("assetTypeId", asset.assetTypeId);
            setValue("subAssetTypeId", asset.subAssetTypeId);
            setValue("description", asset.description);
            setValue("code", asset.code);
            setValue("uniqueReference", asset.uniqueReference);
            setValue("troubleshootingGuide", asset.troubleshootingGuide);
        }
    }, [asset])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const loadFile = async () => {
        var result = await DocumentPicker.getDocumentAsync({
            type: ".png"
        }) as any;

        if (result.assets.length == 0) return;
        const file = result.assets[0].file;

        var base64 = (await toBase64(file)) as string;

        setValue("imageBase64", base64);
    }

    const submit = async (data) => {
        onSubmit(data);
    }

    return (
        <ScrollView>
            <View style={GlobalStyle.form}>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="buildingId"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Building (*)</Text>
                                    <Picker style={GlobalStyle.form__column__input}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        itemStyle={{ height: 50 }}>

                                        <Picker.Item label="Please Select" value="" />

                                        {buildings != null && buildings.map((value, index) => {
                                            return (
                                                <Picker.Item key={value.id} label={value.name} value={value.id} />
                                            )
                                        })}
                                    </Picker>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="floorId"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Floor (*)</Text>
                                    <Picker style={GlobalStyle.form__column__input}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        itemStyle={{ height: 50 }}>

                                        <Picker.Item label="Please Select" value="" />

                                        {buildingId && floors.filter(f => f.buildingId === buildingId).map((value, index) => {
                                            return (
                                                <Picker.Item key={value.id} label={value.name} value={value.id} />
                                            )
                                        })}
                                    </Picker>
                                </View>
                            )}
                        />
                    </View>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="locationId"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                                    <Picker style={GlobalStyle.form__column__input}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        itemStyle={{ height: 50 }}>

                                        <Picker.Item label="Please Select" value="" />

                                        {floorId && locations.filter(l => l.floorId === floorId).map((value, index) => {
                                            return (
                                                <Picker.Item key={value.id} label={value.name} value={value.id} />
                                            )
                                        })}
                                    </Picker>
                                </View>
                            )}
                        />
                    </View>


                </View>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="assetTypeId"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Asset Type (*)</Text>
                                    <Picker style={GlobalStyle.form__column__input}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        itemStyle={{ height: 50 }}>

                                        <Picker.Item label="Please Select" value="" />

                                        {assetTypes != null && assetTypes.map((value, index) => {
                                            return (
                                                <Picker.Item key={value.id} label={value.name} value={value.id} />
                                            )
                                        })}
                                    </Picker>
                                </View>
                            )}
                        />
                    </View>

                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="subAssetTypeId"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Sub Asset Type (*)</Text>
                                    <Picker style={GlobalStyle.form__column__input}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        itemStyle={{ height: 50 }}>

                                        <Picker.Item label="Please Select" value="" />

                                        {assetTypeId != null && subAssetTypes.filter(sa => sa.assetTypeId === assetTypeId).map((value, index) => {
                                            return (
                                                <Picker.Item key={value.id} label={value.name} value={value.id} />
                                            )
                                        })}
                                    </Picker>
                                </View>
                            )}
                        />
                    </View>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="description"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Description</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={[GlobalStyle.form__row]}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="uniqueReference"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>CoreRFID QR</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="code"
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Code</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                    <View style={[GlobalStyle.form__column, { justifyContent: 'flex-end' }]}>
                        <TouchableOpacity style={[GlobalStyle.upload__button, { marginBottom: 0 }]} onPress={() => loadFile()}>
                            <Text style={GlobalStyle.form__submit__button__text}>Upload Picture</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={GlobalStyle.form__column}>
                    <View>
                        {imageBase64 ? (
                            <Image
                                style={{ width: 320, height: 320, marginBottom: 10, marginTop: 10 }}
                                resizeMode={"contain"}
                                source={{
                                    uri: imageBase64
                                }}
                            />
                        ) : asset?.imageUri ? (
                            <Image
                                style={{ width: 320, height: 320, marginBottom: 10, marginTop: 10 }}
                                resizeMode={"contain"}
                                source={{
                                    uri: asset?.imageUri,
                                }}
                            />
                        ) : null}
                    </View>
                </View>
                <View style={GlobalStyle.form__row}>
                    <View style={GlobalStyle.form__column}>
                        <Controller
                            control={control}
                            name="troubleshootingGuide"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <View>
                                    <Text style={GlobalStyle.form__column__text}>Troubleshooting Guide</Text>
                                    <TextInput
                                        style={GlobalStyle.form__column__input}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        multiline
                                        numberOfLines={5}
                                        value={value}>
                                    </TextInput>
                                </View>
                            )}
                        />
                    </View>
                </View>
            </View>
            <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
                <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
            </TouchableOpacity>
        </ScrollView>
    )
};

const styles = StyleSheet.create({
    container: {
        //flex: 1,
        //position: 'relative',
        padding: 10,
    },
    form__rows: {
        marginRight: 5,
        flexDirection: "row",
        alignItems: "center"
    },

    form__label: {
        fontFamily: 'OpenSans-Light',
        fontWeight: 'bold',
        paddingTop: 10,
        fontSize: 14,
        flex: 1
    },

    form__input: {
        flex: 2,
        fontFamily: 'OpenSans-Regular',
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CECECE',
        marginTop: 5,
    },

});
