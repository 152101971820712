import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import { assetApi, useAddAssetMutation, useUpdateAssetMutation } from '../../../../store/services/api/asset-api';
import { useGetAllBuildingsQuery, useGetAllFloorsQuery, useGetAllLocationsQuery } from '../../../../store/services/api/building-api';
import { AssetManageControl } from './AssetManageControl';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import { useGetAllAssetTypesQuery, useGetAllSubAssetTypesQuery } from '../../../../store/services/api/assetType-api';
import { PlatformType, compressImage } from '../../../../utils/imageUtils';



export default function AssetManageScreen({ navigation, route }: { navigation: any, route: any }) {

    const [createAsset, createAssetResult] = useAddAssetMutation();
    const [updateAsset, updateAssetResult] = useUpdateAssetMutation();

    const { data: buildings } = useGetAllBuildingsQuery(null);
    const { data: floors } = useGetAllFloorsQuery(null);
    const { data: locations } = useGetAllLocationsQuery(null);
    const { data: assetTypes } = useGetAllAssetTypesQuery(null);
    const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

    const [trigger, assetResult] = assetApi.useLazyGetAssetQuery();

    React.useEffect(() => {
        if (route.params?.assetId != null) {
            trigger(route.params?.assetId);
        }
    }, [])

    const onSubmit = async (data) => {
        var result = null;

        const platform = PlatformType.Web;
        if (data.imageBase64) {
            const compressedImage = await compressImage(data.imageBase64, platform);
            data.imageBase64 = compressedImage;
        }

        if (data.id != null) {
            result = await updateAsset(data) as any;
        } else {
            result = await createAsset(data) as any;
        }

        if (result.error) {
            return;
        };
        navigation.goBack();
    }

    if (createAssetResult.isLoading || updateAssetResult.isLoading) {
        return <LoadingIndicator />
    }

    return (
        <View style={styles.container}>
            <View style={GlobalStyle.sub__title}>
                <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("list")}>
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
                <Text style={GlobalStyle.sub__title__text}>{"MANAGE ASSET"}</Text>
            </View>

            <AssetManageControl
                buildings={buildings}
                floors={floors}
                locations={locations}
                assetTypes={assetTypes}
                subAssetTypes={subAssetTypes}
                asset={assetResult?.data}
                onSubmit={onSubmit} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    },
});

