import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user-slice";
import assetReducer from "./slices/asset-slice";
import ticketReducer from "./slices/ticket-slice";

import { setupListeners } from "@reduxjs/toolkit/query";
import { baseApi } from "./services/api/base-api";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const reducers = combineReducers({
  user: userReducer,
  asset: assetReducer,
  ticket: ticketReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      baseApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
