import { createNativeStackNavigator } from '@react-navigation/native-stack';
import TicketsListScreen from './TicketsListScreen';

const Stack = createNativeStackNavigator();

export default function AssetsScreen ({ navigation }: { navigation: any }) {
    return (
        <Stack.Navigator>
          <Stack.Screen name="list" component={TicketsListScreen} options={{ headerShown: false, title: 'Tickets' }} />
        </Stack.Navigator>
      )
}