import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useFonts } from 'expo-font';
import { Provider } from 'react-redux';
import { store } from './store'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import WebNavigator from './screens/WebNavigator'
import MobileNavigator from './screens/MobileNavigator'

import { LogBox } from 'react-native';
import LoginScreen from './screens/web/screens/LoginScreen';

const Stack = createNativeStackNavigator();

const persistor = persistStore(store);

LogBox.ignoreLogs([
  "Looks like you're passing an inline function for 'component'", 
  "Deprecation warning: value", 
  "FlashList's rendered", 
  "redux-persist failed to create sync storage. falling back to noop storage.",
]);

export default function App() {
  const [fontsLoaded] = useFonts({
    'OpenSans-Light': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Light.ttf'),
    'OpenSans-Regular': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf'),
    'OpenSans-Bold': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf'),
    'OpenSans-ExtraBold': require('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf'),
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationContainer linking={{ enabled: true }}>
          <Stack.Navigator options={{ animationEnabled: true }}>
            <Stack.Screen name="login" component={LoginScreen} options={{ headerShown: false,  }} />  
            <Stack.Screen name="web" component={WebNavigator} options={{ headerShown: false, title: "Web" }}  />
            <Stack.Screen name="mobile" component={MobileNavigator} options={{ headerShown: false, title: "Mobile" }}  />
          </Stack.Navigator>
        </NavigationContainer>
      </PersistGate>
    </Provider>
  );
}
