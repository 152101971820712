import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AssetTypesListScreen from './AssetTypesListScreen';
import AssetTypeManageScreen from './AssetTypeManageScreen';

const Stack = createNativeStackNavigator();

export default function AssetTypesScreen({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={AssetTypesListScreen} options={{ headerShown: false, title: 'Asset Types' }} />
      <Stack.Screen name="manage" component={AssetTypeManageScreen} options={{ headerShown: false, title: 'Asset Type Manage' }} />
    </Stack.Navigator>
  )
}