import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Modal, Image, TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

export const ImageViewer = ({ images, onClosed }) => {
    const [selectedImageIdx, setSelectedImageIdx] = useState(0);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code == "ArrowRight") {
                nextImage();
            } else if (event.code == "ArrowLeft") {
                prevImage();
            } else if (event.code == "Escape") {
                onClosed();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedImageIdx]);

    const nextImage = () => {
        var idx = selectedImageIdx + 1;
        if (idx > images.length - 1)
            return;

        setSelectedImageIdx(idx);
    }

    const prevImage = () => {
        if (selectedImageIdx == 0)
            return;

        var idx = selectedImageIdx - 1;
        setSelectedImageIdx(idx);
    }

    const isLeftArrowVisible = selectedImageIdx > 0;
    const isRightArrowVisible = selectedImageIdx < images.length - 1;

    return (
        <View style={styles.container}>
            <Modal
                animationType="fade"
                transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredContainer}>
                        <View style={styles.imageViewerContainer}>
                            <TouchableOpacity onPress={prevImage} style={styles.arrowButton}>
                                <AntDesign name="left" size={24} color={isLeftArrowVisible ? "black" : "white"} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={onClosed} style={styles.closeButton}>
                                <AntDesign name="closecircle" size={24} color="black" />
                            </TouchableOpacity>
                            <Image
                                style={styles.image}
                                resizeMode="contain"
                                source={{ uri: images[selectedImageIdx] }}
                            />
                            <TouchableOpacity onPress={nextImage} style={styles.arrowButton}>
                                <AntDesign name="right" size={24} color={isRightArrowVisible ? "black" : "white"} />
                            </TouchableOpacity>
                            <Text style={styles.imageText}>
                                Image {selectedImageIdx + 1} of {images.length}
                            </Text>
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    centeredContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    imageViewerContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#FFF',
        width: 650,
        height: 600,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    image: {
        flex: 2,
        height: 500,
        width: 500,
    },
    imageText: {
        marginTop: 10,
        fontWeight: 'bold'
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        padding: 10,
    },
    arrowButton: {
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
        transform: [{ translateY: -12 }],
    }
});
