import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface TicketState {
    id: string | null;
    number: string | null;
    issue: string | null;
    contactEmail: string | null;
    status: string | null;
    assetId: string | null;
    createdOn: string | null;
    lastModifiedOn: string | null;
}

const initialState: TicketState = {
    id: null,
    number: null,
    issue: null,
    contactEmail: null,
    status: null,
    assetId: null,
    createdOn: null,
    lastModifiedOn: null,
};

export const TicketSlice = createSlice({
    name: "Ticket",
    initialState,
    reducers: {
        setTicket: (state, action: PayloadAction<any>) => {
            if (action.payload.id != null) state.id = action.payload.id;
            if (action.payload.number != null)
                state.number = action.payload.number;
            if (action.payload.issue != null)
                state.issue = action.payload.issue;
            if (action.payload.contactEmail != null)
                state.contactEmail = action.payload.contactEmail;
            if (action.payload.status != null)
                state.status = action.payload.status;
            if (action.payload.assetId != null)
                state.assetId = action.payload.assetId;
            if (action.payload.createdOn != null)
                state.createdOn = action.payload.createdOn;
            if (action.payload.lastModifiedOn != null)
                state.lastModifiedOn = action.payload.lastModifiedOn;
        },

        clearTicket: (state) => {
            state.id = null;
            state.number = null;
            state.issue = null;
            state.contactEmail = null;
            state.status = null;
            state.assetId = null;
            state.createdOn = null;
            state.lastModifiedOn = null;
        },
    },
});

export const { setTicket, clearTicket } = TicketSlice.actions;

export default TicketSlice.reducer;
