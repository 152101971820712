import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AssetsListScreen from "./AssetsListScreen";
import AssetManageScreen from "./AssetManageScreen";

const Stack = createNativeStackNavigator();

export default function AssetsScreen({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={AssetsListScreen} options={{ headerShown: false, title: 'Assets' }} />
      <Stack.Screen name="manage" component={AssetManageScreen} options={{ headerShown: false, title: 'Asset Manage' }} />
    </Stack.Navigator>
  )
}