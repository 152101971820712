import { baseApi, PagedTableDto } from './base-api'

export const assetApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAsset: build.query<any, any>({
      query: (id) => ({
        url: `assets/getasset/${id}`,
      }),
    }),
    getAssetByUniqueRef: build.query<any, any>({
      query: (uniqueRef) => ({
        url: `assets/getassetbyuniqueref/${uniqueRef}`,
      }),
    }),
    getAssetByCode: build.query<any, any>({
      query: (code) => ({
        url: `assets/getassetbycode/${code}`,
      }),
    }),
    getAssets: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `assets/getassets?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    addAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'assets/addasset',
        method: 'post',
        body: body
      }),
    }),
    updateAsset: build.mutation<any, any>({
      query: (body) => ({
        url: 'assets/updateasset',
        method: 'put',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetAssetsQuery,
  useGetAssetQuery,
  useGetAssetByUniqueRefQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useGetAssetByCodeQuery
} = assetApi