import { StyleSheet } from 'react-native';

export const PRIMARY_COLOUR = '#2e75b6';
export const STAR_COLOUR = '#ffc000';

export default StyleSheet.create({
  required: {
    color: 'red',
    fontFamily: 'OpenSans-Bold'
  },

  table: {
    backgroundColor: '#FFF'
  },

  sub__title: {
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: '#CECECE',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },

  sub__title__icon: {
    marginLeft: 10,
  },

  sub__title__text: {
    color: PRIMARY_COLOUR,
    padding: 10,
    fontFamily: 'OpenSans-Bold'
  },

  filters: {
    backgroundColor: '#FFF',
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#CECECE'
  },

  filters__header: {
    backgroundColor: PRIMARY_COLOUR,
  },

  filters__header__text: {
    padding: 10,
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    color: '#FFF'
  },

  filter__columns: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    flexWrap: 'wrap'
  },

  filter__search__button: {
    padding: 5,
    backgroundColor: PRIMARY_COLOUR,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
    alignSelf: 'flex-end',
    marginTop: 27,
  },

  filter__clear__button: {
    padding: 5,
    backgroundColor: '#FFF',
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    borderWidth: 1,
    borderColor: '#CECECE',
    alignSelf: 'flex-end',
    marginTop: 27,
  },

  column__header: {
    marginBottom: 5,
    fontWeight: 'bold',
    fontFamily: 'OpenSans-Light',
  },

  column: {
    minWidth: "auto"
  },

  column__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 7,
    fontFamily: 'OpenSans-Light',
    marginRight: 10,
  },

  column__flex: {
    flexDirection: 'column',
    flex: 1
  },

  add__new__link: {
    marginBottom: 10,
    fontFamily: 'OpenSans-Bold',
    color: PRIMARY_COLOUR,

    flexDirection: 'row',
    alignItems: 'center',
  },

  add__button: {
    backgroundColor: PRIMARY_COLOUR,
    marginBottom: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: PRIMARY_COLOUR,
    alignSelf: 'flex-start'
  },

  upload__button: {
    backgroundColor: PRIMARY_COLOUR,
    marginBottom: 10,
    marginLeft: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: PRIMARY_COLOUR,
    alignSelf: 'flex-start'
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 10,
  },

  form__row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  form__column: {
    flex: 1,
    margin: 5,
    maxWidth: '33%',
  },

  form__column__third: {
    minWidth: '32%',
  },

  form__column__full: {
    maxWidth: '100%',
  },

  form__column__text: {
    fontFamily: 'OpenSans-Light',
  },

  form__column__input: {
    fontFamily: 'OpenSans-Regular',
    padding: 5,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
  },

  form__submit__button: {
    marginTop: 10,
    backgroundColor: PRIMARY_COLOUR,
    width: 100,
    marginLeft: 5,
  },
  form__submit__button__disabled: {
    backgroundColor: 'grey',
    marginTop: 10,
    width: 100,
    marginLeft: 5,
  },

  form__submit__button__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    color: '#FFF',
    textAlign: 'center'
  },

  form__input__validation: {
    fontFamily: 'OpenSans-Bold',
    color: 'red'
  },
  spinnerTextStyle: {
    color: '#FFF'
  },
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
  mobile__page__container: {
    flex: 1,
    backgroundColor: "white",
  },
  mobile__button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#2C89C6",
    paddingVertical: 15,
    paddingHorizontal: "10%",
    marginVertical: 10,
    borderRadius: 5,
    width: "90%",
    justifyContent: "center"
  }

});