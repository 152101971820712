import * as React from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import { systemSettingsApi, useCreatePinMutation, useUpdatePinMutation } from '../../../../store/services/api/systemSettings-api';
import { useEffect, useState } from 'react';


export default function SystemSettingsScreen({navigation, route}: {navigation: any, route: any}) {
  const [createPin, createPinResult] = useCreatePinMutation();
  const [updatePin, updatePinResult] = useUpdatePinMutation();
  const [pin, setPin] = useState<any>("");

  const [trigger, pinResult] = systemSettingsApi.useLazyGetPinQuery();

  const { control, handleSubmit, getValues, setValue, formState: { errors, isDirty } } = useForm({
    defaultValues: {
      id: null,
      value: ""
    }
  });

  useEffect(() => {
      trigger(null);
  }, [])

  useEffect(() => {
    if (pinResult.isSuccess) {
      var data = pinResult.data;
      setValue("id", data.id);
      setValue("value", data.value);
      setPin(data.value);
    }
  }, [pinResult]);

  const validatePin = async (name) => {
    if (!name) {
      return "Pin is required.";
    }
  };

    const onSubmit = async (data) => {
        let result = null;

        if (data.id != null || data.pin === pin) {
            result = await updatePin(data) as any;
        } else {
            result = await createPin(data) as any;
        }
        
        if (result.error) {
            return;
        }
        
        navigation.navigate("assets");
    };

  if (createPinResult.isLoading || updatePinResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={GlobalStyle.container}>
      <View style={GlobalStyle.sub__title}>
        <Text style={GlobalStyle.sub__title__text}>{getValues("value") == null ? "CREATE PIN" : "UPDATE PIN"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{
                validate: validatePin
              }}
              name="value"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Admin Pin (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.value && (
              <Text style={GlobalStyle.form__input__validation}>
                {errors.value.message || "Validation error."}
              </Text>
            )}
          </View>
        </View>

        <TouchableOpacity 
            style={[GlobalStyle.form__submit__button, !isDirty && GlobalStyle.form__submit__button__disabled]}
            onPress={handleSubmit(onSubmit)} 
            disabled={!isDirty}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

