import { baseApi, PagedTableDto } from './base-api'

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `users/getUsers?${dto.getFilters()}`,
      }),
    }),
    getUser: build.query<any, any>({
      query: (userId) => ({
        url: `users/getuser/${userId}`,
      }),
    }),
    createUser: build.mutation<any, any>({
      query: (body) => ({
        url: 'users/adduser',
        method: 'post',
        body: body
      }),
    }),
    updateUser: build.mutation<any, any>({
      query: (body) => ({
        url: `users/updateuser/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteUser: build.mutation<any, any>({
      query: (partId) => ({
        url: `users/deleteuser/${partId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useGetUsersQuery,
  useGetUserQuery, 
  useCreateUserMutation, 
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi