import { useWindowDimensions } from 'react-native';
import { DrawerContent } from './web/components/navigation/DrawerContent';
import { createDrawerNavigator } from '@react-navigation/drawer';
import AssetsScreen from './web/screens/Assets/AssetsScreen';
import TicketsScreen from './web/screens/Tickets/TicketsScreen';
import React, { useEffect } from 'react';
import WebUsers from './web/screens/Users/WebUsers';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Header } from './web/components/navigation/Header';
import SystemSettings from './web/screens/SystemSettings/SystemSettings';
import Feedback from './web/screens/Feedback/Feedback';
import BuildingsScreen from './web/screens/Buildings/BuildingsScreen';
import AssetTypesScreen from './web/screens/AssetTypes/AssetTypesScreen';

const Drawer = createDrawerNavigator();

export default function WebNavigator({ navigation }: { navigation: any }) {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (user == null) {
      navigation.navigate("login")
    }
  }, [user])

  return (
    <Drawer.Navigator
      detachInactiveScreens={true}
      defaultStatus="open"
      screenOptions={{
        drawerType: isLargeScreen ? 'permanent' : 'back',
        drawerStyle: isLargeScreen ? { width: 250 } : { width: '100%' },
        overlayColor: 'transparent',
        header: (props) => Header(props, user),
        unmountOnBlur: true
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Drawer.Screen name="assets" component={AssetsScreen} options={{ title: 'Assets' }} />
      <Drawer.Screen name="assetTypes" component={AssetTypesScreen} options={{ title: 'Asset Types' }} />
      <Drawer.Screen name="tickets" component={TicketsScreen} options={{ title: 'Tickets' }} />
      <Drawer.Screen name="users" component={WebUsers} options={{ title: 'Users' }} />
      <Drawer.Screen name="buildings" component={BuildingsScreen} options={{ title: 'Buildings' }} />
      <Drawer.Screen name="system-settings" component={SystemSettings} options={{ title: 'System Settings' }} />
      <Drawer.Screen name="feedback" component={Feedback} options={{ title: 'Feedback' }} />
    </Drawer.Navigator>
  );
}

