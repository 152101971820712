import { AntDesign, FontAwesome5, FontAwesome, SimpleLineIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity, ScrollView, SafeAreaView } from "react-native";
import { useRoute, useIsFocused } from "@react-navigation/native";
import { useGetAssetByUniqueRefQuery } from "../../../../store/services/api/asset-api";
import { LoadingIndicator } from "../../../web/components/LoadingIndicator";
import GlobalStyle, { PRIMARY_COLOUR, STAR_COLOUR } from "../../../../constants/GlobalStyle";
import { useDispatch } from "react-redux";
import { clearAsset, setAsset } from "../../../../store/slices/asset-slice";
import { feedbackApi } from "../../../../store/services/api/feedback-api";
import { Badge, Banner } from 'react-native-paper';

export default function AssetViewScreen({ navigation }) {
  const route = useRoute();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const [bannerVisible, setBannerVisible] = useState(false);

  const { uniqueRef } = route.params ?? {};

  const [trigger, { currentData: averageRatingResult }] = feedbackApi.useLazyGetAverageRatingQuery();
  const { data: asset, isError, refetch } = useGetAssetByUniqueRefQuery(uniqueRef);

  useEffect(() => {
    if (asset) {
      dispatch(clearAsset());
      dispatch(setAsset(asset));
      trigger(asset.id);
    }
  }, [asset, route]);

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused, refetch]);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <Ionicons
            key={`full-${i}`}
            name="star"
            size={30}
            color={STAR_COLOUR}
          />
        );
      } else if (i === Math.ceil(rating) && rating % 1 > 0) {
        stars.push(
          <Ionicons
            key={`half-${i}`}
            name="star-half-outline"
            size={30}
            color={STAR_COLOUR}
          />
        );
      } else {
        stars.push(
          <Ionicons
            key={`empty-${i}`}
            name="star-outline"
            size={30}
            color={STAR_COLOUR}
          />
        );
      }
    }
    return stars;
  };

  const handleCreateTicketPress = () => {
    if (asset && asset.ticketsCount > 0) {
      setBannerVisible(!bannerVisible);
    } else {
      navigation.navigate("ticket-screen");
    }
  };

  return (
    <SafeAreaView style={GlobalStyle.mobile__page__container}>
      <View style={styles.header}>
        <TouchableOpacity
          onPress={() => {
            if (asset) {
              navigation.navigate("pin-screen", {
                nextScreen: "asset-options-screen",
              });
            }
          }}
        >
          <FontAwesome name="cog" size={32} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <Banner
          visible={bannerVisible}
          actions={[
            {
              label: 'View Status',
              onPress: () => {
                setBannerVisible(false);
                navigation.navigate('ticket-screen', { screen: 'open-tickets-screen' })
              }
            },
            {
              label: 'Create Ticket',
              onPress: () => {
                setBannerVisible(false);
                navigation.navigate("ticket-screen");
              },
            },
          ]}
          style={styles.banner}
        >
          <Text style={styles.bannerText}>
            {`There are ${asset && asset.ticketsCount} tickets currently open for this item.`}
          </Text>
        </Banner>
        {asset && !isError ? (
          <View style={{ height: '43%', width: "100%", justifyContent: "center" }}>
            <Image
              style={styles.image}
              resizeMode={"center"}
              source={{
                uri: asset.imageUri ? asset.imageUri : LoadingIndicator,
              }}
            />
          </View>
        ) : (
          <Text style={styles.title}>
            Asset is not found. Please try again {"\n"}
            or consider adding it
          </Text>
        )}
        <View style={{ flexDirection: "row", alignItems: 'stretch', width: '90%', justifyContent: 'center', alignSelf: 'center' }}>
          <Text style={styles.title}>
            {asset
              ? `${asset.description}`
              : isError
                ? ""
                : "Loading asset..."}
          </Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: 'stretch', width: '90%', justifyContent: 'center', alignSelf: 'center' }}>
          <View style={styles.ratingContainer}>
            {averageRatingResult &&
              renderStars(averageRatingResult.averageRating)}
          </View>
        </View>
        <View style={styles.buttonContainer}>
          {!isError && (
            <View>
              <TouchableOpacity
                onPress={handleCreateTicketPress}
                style={styles.button}
              >
                <AntDesign name="plus" size={38} color="white" />
                <Text style={styles.buttonText}>Create Ticket</Text>
                {asset && asset.ticketsCount > 0 && (
                  <Badge style={styles.badge} size={28}>{asset.ticketsCount}</Badge>
                )}
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  if (asset) {
                    navigation.navigate("asset-troubleshooting-screen");
                  }
                }}
                style={styles.button}
              >
                <SimpleLineIcons name="magnifier" size={36} color="white" />
                <Text style={styles.buttonText}>Troubleshoot</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  if (asset) {
                    navigation.navigate("asset-feedback-screen");
                  }
                }}
                style={styles.button}
              >
                <MaterialCommunityIcons name="message-plus-outline" size={38} color="white" />
                <Text style={styles.buttonText}>Feedback</Text>
              </TouchableOpacity>
            </View>
          )}

          {isError && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("pin-screen", {
                  nextScreen: "asset-setup-screen",
                  uniqueRef: uniqueRef,
                });
              }}
              style={styles.button}
            >
              <FontAwesome5 name="cog" size={36} color="white" />
              <Text style={styles.buttonText}>Setup Asset</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={{ width: '90%', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Image
            source={require("../../../../assets/images/coreLogoSmall.png")}
            style={styles.tinyLogo}
            resizeMode="contain"
          />
          <Image
            source={require("../../../../assets/images/pfizerLogoSmall.png")}
            style={styles.tinyLogo}
            resizeMode="contain"
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: 'space-between',
  },
  header: {
    marginTop: 20,
    alignSelf: "flex-end",
    marginRight: 20,
  },
  image: {
    height: "100%",
    width: "80%",
    marginTop: 10,
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    marginVertical: 5,
    textAlign: "center",
    paddingHorizontal: "5%",
  },
  buttonContainer: {
    marginVertical: 5,
    width: "80%",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#2C89C6",
    paddingVertical: 8,
    paddingHorizontal: "10%",
    marginVertical: 10,
    borderRadius: 5,
    width: "100%",
    justifyContent: "center",

  },
  buttonText: {
    color: "white",
    marginLeft: 10,
    fontSize: 20,
  },
  footer: {
    fontSize: 16,
    color: "#3B3B3B",
    marginBottom: 20,
  },
  ratingContainer: {
    flex: 3,
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 5,
    marginVertical: 5,
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  tinyLogo: {
    width: 90,
    height: 50,
  },
  badge: {
    position: 'absolute',
    top: -5,
    right: -5,
    backgroundColor: 'red',
    color: 'white',
  },
  banner: {
    width: "100%",
  },

  bannerText: {
    textAlign: 'right',
    fontSize: 15
  },
});
