import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView } from "react-native";
import { EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { useGetTicketByAssetQuery } from "../../../../store/services/api/ticket-api";
import { DataTable, TextInput } from "react-native-paper";

export default function TicketStatusScreen({ navigation }) {
  const asset = useSelector((state: RootState) => state.asset);
  const {
    data: ticket,
    isError,
  } = useGetTicketByAssetQuery(asset.id);

  return (
    <SafeAreaView style={GlobalStyle.mobile__page__container}>
      <View style={styles.header}>
        <TouchableOpacity
          style={GlobalStyle.sub__title__icon}
          onPress={() =>
            navigation.navigate('asset-screen')
          }
        >
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
      </View>
      {!isError ? (<ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={[styles.section, styles.flexRow]}>
          <Text style={[styles.title, styles.flexGrow]}>Ticket Number</Text>
          <TextInput
            mode="outlined"
            style={styles.inputTicketNumber}
            value={ticket ? ticket.number : "Loading..."}
            disabled={true}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>Ticket Status</Text>
          <TextInput
            mode="outlined"
            style={styles.input}
            value={ticket ? ticket.status : "Loading..."}
            disabled={true}
          />
        </View>
        <ScrollView>
          <View style={styles.section}>
            <Text style={styles.title}>Ticket Description</Text>
            <TextInput
              mode="outlined"
              style={styles.input}
              value={ticket ? ticket.description : "Loading..."}
              disabled={true}
              multiline
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Updates</Text>
            <DataTable>
              <DataTable.Header>
                <DataTable.Title>Date</DataTable.Title>
                <DataTable.Title>Update</DataTable.Title>
              </DataTable.Header>

              {ticket && ticket.updates.length > 0 ? (
                ticket.updates.map((update, index) => (
                  <DataTable.Row key={index}>
                    <DataTable.Cell>{update.dateCreated}</DataTable.Cell>
                    <DataTable.Cell style={styles.multiLineCell}>
                      <Text>{update.text}</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                ))
              ) : (
                <DataTable.Row>
                  <DataTable.Cell>No Updates</DataTable.Cell>
                </DataTable.Row>
              )}
            </DataTable>
          </View>
        </ScrollView>
      </ScrollView>) :
        (<View style={styles.section}>
          <Text style={styles.title}>No tickets raised</Text>
        </View>)}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "flex-start",
    width: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 20,
    width: "100%",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  input: {
    flex: 1,
    backgroundColor: "#f2f2f2",
  },
  section: {
    width: "90%",
    height: "auto",
    alignItems: "stretch",
    alignSelf: "center",
    marginBottom: 20,
    paddingHorizontal: "5%",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: "5%",
    width: "90%",
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexShrink: {
    flexBasis: "10%",
    flexShrink: 1,
    marginLeft: 10,
  },
  inputTicketNumber: {
    flex: 1,
    maxWidth: "20%",
    backgroundColor: "#f2f2f2",
    textAlign: "center",
    marginBottom: 10,
  },
  multiLineCell: {
    flex: 1,
    justifyContent: "flex-start",
  },
});