import { View, Text, StyleSheet } from "react-native";
import { MaterialIcons, Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';

var logo = require('../../../../assets/corerfidlogo.png');

export const DrawerContent = (props) => {

  const renderMenu = () => {
    return (
      <View>
        <View>
          <View style={styles.logo__container}>
            <img src={logo} style={styles.logo} />
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("assets")}>
              <MaterialIcons style={styles.link__icon} name="web-asset" size={18} color="black" />
              Assets
            </Text>
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("tickets")}>
              <MaterialIcons style={styles.link__icon} name="check-circle" size={18} color="black" />
              Tickets
            </Text>
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("feedback")}>
              <MaterialIcons style={styles.link__icon} name="chat" size={18} color="black" />
              Feedback
            </Text>
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("users")}>
              <Feather style={styles.link__icon} name="users" size={18} color="black" />
              Users
            </Text>
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("buildings")}>
              <FontAwesome5 style={styles.link__icon} name="building" size={18} color="black" />
              Buildings
            </Text>
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("assetTypes")}>
              <MaterialCommunityIcons style={styles.link__icon} name="format-list-bulleted-type" size={18} color="black" />
              Asset Types
            </Text>
          </View>
          <View>
            <Text style={[styles.link, styles.sub__menu]} onPress={() => props.navigation.navigate("system-settings")}>
              <FontAwesome style={styles.link__icon} name="cog" size={18} color="black" />
              System Settings
            </Text>
          </View>
        </View >
      </View >
    );
  };



  return (
    <View style={styles.navigation}>
      {renderMenu()}
    </View>
  );
}

const styles = StyleSheet.create({
  navigation: {
    height: '100%',
    overflowY: 'scroll',
    paddingBottom: 10
  },

  sub__menu: {
    paddingLeft: 20,
  },

  title: {
    fontFamily: 'OpenSans-ExtraBold',
    padding: 10,
  },

  link: {
    padding: 10,
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'OpenSans-Regular'
  },

  link__icon: {
    paddingRight: 10,
  },

  logo__container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },

  logo: {
    height: 60,
    resizeMode: 'contain',
    marginTop: 10,
  },
});