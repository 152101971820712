import { useIsFocused } from "@react-navigation/core";
import React, { useEffect, useState } from "react";
import { ScrollView, View, StyleSheet, Text, TextInput, TouchableOpacity } from "react-native";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { SimpleLineIcons, FontAwesome } from "@expo/vector-icons";
import { DataTable } from 'react-native-paper';
import Spinner from 'react-native-loading-spinner-overlay';
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { feedbackApi } from "../../../../store/services/api/feedback-api";
import moment from "moment";
import DateTimePicker from "../../components/navigation/controls/DateTimePicker";
import PaginationComponent from "../../components/PaginationComponent";
import { DataTableHeader } from "../../components/DataTableHeader";
import { Picker } from "@react-native-picker/picker";

export default function FeedbackListScreen({ navigation }: { navigation: any }) {
    const isFocused = useIsFocused();
    const [loading, setLoading] = useState<any>(false);
    const [trigger, feedbackResult] = feedbackApi.useLazyGetFeedbackQuery();
    const [page, setPage] = React.useState<number>(0);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [sortedColumn, setSortedColumn] = React.useState<string>("dateSubmittedFull");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(false);

    const [assetReference, setAssetReference] = useState<any>("");
    const [description, setDescription] = useState<any>("");
    const [dateRange, setDateRange] = useState("");
    const [startDate, setStartDate] = useState(moment().add(-1, 'week').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = useState(moment().format('DD/MM/YYYY'));

    useEffect(() => {
        loadTable();
    }, [page, isFocused, numberOfItemsPerPage, sortedColumn, sortedColumnAscending])

    const resetFilters = () => {
        setAssetReference("");
        setDescription("");
        setDateRange("");
        setStartDate(moment().add(-1, 'week').format('DD/MM/YYYY'));
        setEndDate(moment().format('DD/MM/YYYY'));
    }

    const updateDateRange = (val) => {
        setDateRange(val);

        let startDate = moment();
        const endDate = moment();
        if (val == "-1") {
            startDate = moment().add(-1, 'week');
        } else {
            startDate = moment().add(-Number.parseInt(val), 'M');
        }

        setStartDate(moment(startDate).format('DD/MM/YYYY'));
        setEndDate(moment(endDate).format('DD/MM/YYYY'));
    }

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(
                    <FontAwesome
                        key={`full-${i}`}
                        name="star"
                        size={24}
                        color={PRIMARY_COLOUR}
                    />
                );
            } else if (i === Math.ceil(rating) && rating % 1 > 0) {
                stars.push(
                    <FontAwesome
                        key={`half-${i}`}
                        name="star-half-empty"
                        size={24}
                        color={PRIMARY_COLOUR}
                    />
                );
            } else {
                stars.push(
                    <FontAwesome
                        key={`empty-${i}`}
                        name="star-o"
                        size={24}
                        color={PRIMARY_COLOUR}
                    />
                );
            }
        }
        return stars;
    };


    const loadTable = () => {
        const formattedStartDate = moment(startDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        const formattedEndDate = moment(endDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        var dto = new PagedTableDto(page, {
            assetReference,
            description,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            pageSize: numberOfItemsPerPage
        }, sortedColumn, sortedColumnAscending);

        trigger(dto).then(() => {
            setLoading(false);
        });
    }

    return (
        <ScrollView style={GlobalStyle.container}>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />

            <View style={GlobalStyle.filters}>
                <View style={GlobalStyle.filters__header}>
                    <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
                </View>

                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Date Range</Text>
                        <Picker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            selectedValue={dateRange}
                            onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

                            <Picker.Item label="Predefined Date Range" value="" />
                            <Picker.Item label="1 Week" value="-1" />
                            <Picker.Item label="1 Month" value="1" />
                            <Picker.Item label="3 Months" value="3" />
                            <Picker.Item label="6 Months" value="6" />
                            <Picker.Item label="12 Months" value="12" />

                        </Picker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Start Date</Text>
                        <DateTimePicker
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChange={setStartDate}
                            value={startDate}>
                        </DateTimePicker>
                    </View>

                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>End Date</Text>
                        <DateTimePicker
                            style={[GlobalStyle.column, GlobalStyle.column__input,]}
                            onChange={setEndDate}
                            value={endDate}>
                        </DateTimePicker>
                    </View>
                </View>
                <View style={GlobalStyle.filter__columns}>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Asset Reference</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setAssetReference}
                            value={assetReference}
                        />
                    </View>
                    <View style={GlobalStyle.column__flex}>
                        <Text style={GlobalStyle.column__header}>Description</Text>
                        <TextInput
                            style={[GlobalStyle.column, GlobalStyle.column__input]}
                            onChangeText={setDescription}
                            value={description}
                        />
                    </View>
                    <View style={GlobalStyle.column__flex}>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                                <SimpleLineIcons name="magnifier" size={16} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                                <SimpleLineIcons name="ban" size={16} color="black" />
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </View>

            <DataTable style={GlobalStyle.table}>
                <DataTableHeader
                    sortedColumn={sortedColumn}
                    sortedColumnAscending={sortedColumnAscending}
                    onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                    headers={[
                        { text: 'Asset Reference', sortedName: "uniqueReference", visible: true },
                        { text: 'Star Rating', sortedName: "starRating", visible: true },
                        { text: 'Description', sortedName: "description", visible: true },
                        { text: 'Contact Email', sortedName: "contactEmail", visible: true },
                        { text: 'Date Submitted', sortedName: "dateSubmittedFull", visible: true },
                    ]}
                />

                {feedbackResult.data?.list.map((element: any) => {
                    return (
                        <DataTable.Row key={element.id}>
                            <DataTable.Cell>{element.uniqueReference}</DataTable.Cell>
                            <DataTable.Cell>{renderStars(element.starRating)}</DataTable.Cell>
                            <DataTable.Cell>{element.description}</DataTable.Cell>
                            <DataTable.Cell>{element.contactEmail}</DataTable.Cell>
                            <DataTable.Cell>{element.dateSubmitted}</DataTable.Cell>
                        </DataTable.Row>
                    )
                })}
            </DataTable>

            <PaginationComponent
                numberOfItemsPerPage={numberOfItemsPerPage}
                setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                page={page}
                setPage={setPage}
                fetchResult={feedbackResult}
                setLoading={setLoading}
            />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        padding: 10,
    }

});