import * as React from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Platform } from 'react-native';
import { useForm, Controller } from "react-hook-form";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Constants from "expo-constants";
import { UserService } from '../../../store/services/user-service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GlobalStyle from '../../../constants/GlobalStyle';
import config from '../../../config';
import { setUser } from '../../../store/slices/user-slice';

const VERSION = Constants.expoConfig.version;

export default function LoginScreen({navigation}: {navigation: any}) {
  const dispatch = useDispatch();
  const userService = new UserService();

  const [loading, setLoading] = useState(false);
  const [loginResult, setLoginResult] = useState(null);

  const { control, handleSubmit, getValues, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });


  React.useEffect(() => {
    const getLoggedInUser = async () => {  
        var userJson = await AsyncStorage.getItem("user");
        if (userJson != null && userJson != "") {
            var user = JSON.parse(userJson);
            dispatch(setUser(user));
            
            navigation.navigate('web');
          }
      }
    getLoggedInUser();
  }, []);

  const onSubmit = async (data) => {
    const email = data.email;
    const password = data.password;
    
    setLoading(true);
    var loginResult = await userService.login(email, password);

    setLoading(false);
    setLoginResult(loginResult);
    if (loginResult.error) {
      return;
    }

    var user = loginResult.data;
    
    dispatch(setUser(user));
    
    navigation.navigate('web');
  }

  return (
    <ImageBackground source={require("../../../assets/images/login.png")} resizeMode="cover" style={styles.container}>
        <View style={styles.login}>
          {loading && <View style={styles.loader}><ActivityIndicator size="large" color={config.colours.primary}/></View>}

          <View style={styles.login__input}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={styles.login__input__text}
                  onBlur={onBlur}
                  autoCorrect={false}
                  onChangeText={onChange}
                  value={value}
                  placeholder="Email"
                />
              )}
              name="email"
            />
            {errors.email && <Text style={GlobalStyle.required}>Email is required.</Text>}
          </View>

          <View style={styles.login__input}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={styles.login__input__text}
                  onBlur={onBlur}
                  autoCorrect={false}
                  onChangeText={onChange}
                  value={value}
                  onSubmitEditing={handleSubmit(onSubmit)}
                  secureTextEntry={true}
                  placeholder="Password"
                />
              )}
              name="password"
            />
            {errors.password && <Text style={GlobalStyle.required}>Password is required.</Text>}
          </View>

          { loginResult?.error && <Text style={GlobalStyle.required}>Invalid credentials. Please try again.</Text> }

          <TouchableOpacity style={styles.login__button} onPress={handleSubmit(onSubmit)} >
            <Text style={styles.login__button__text}>LOGIN</Text>
          </TouchableOpacity>

          <TouchableOpacity>
              <Text style={{ marginTop: 10, textAlign: 'center' }}>V: {VERSION}</Text>
            </TouchableOpacity>
        </View>
    </ImageBackground>
  );
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },

    login: {
      backgroundColor: '#FFF',
      padding: 20,
      width: Platform.OS == 'web' ? 400 : '80%',
      borderRadius: 5,
      position: 'relative'
    },

      loader: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        backgroundColor: '#FFF',
        zIndex: 999,
        alignItems: 'center',
        justifyContent: 'center'
      },

      login__input: {
        marginBottom: 10,
      },

        login__input__text: {
          borderWidth: 1,
          borderColor: '#CECECE',
          padding: 7,
          borderRadius: 5,
          fontFamily: 'OpenSans-Regular'
        },
      
      login__button: {
        backgroundColor: config.colours.primary,
        marginTop: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: config.colours.primary,
      },

      login__button__text: {
        padding: 5,
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'OpenSans-ExtraBold'
      },

      sync__button: {
        backgroundColor: "#FFF",
        marginTop: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: config.colours.primary,
      },

      sync__button__text: {
        padding: 5,
        color: config.colours.primary,
        textAlign: 'center',
        fontFamily: 'OpenSans-ExtraBold'
      }

});
