import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

export default function DateTimePicker({ value, onChange, style }: Props) {
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate); 
    onChange(selectedDate);
  };

  if (Platform.OS === "web") {
    const options = {
      enableTime: false,
      dateFormat: 'd/m/Y',
      allowInput: true,
      utc: true,
      appendTo: document.body,
      onReady: function () {
        const customStyleTag = document.createElement("style");
        customStyleTag.innerHTML = customStyles;
        document.head.appendChild(customStyleTag);
      },
    };

    const customStyles = `
    .flatpickr-input {
        border: 1px solid #CECECE !important;
        padding: 7px !important;
        font-family: 'OpenSans-Light' !important;
        margin-right: 10px !important;
    }
  `;

  return (
    <Flatpickr
      value={date} 
      onChange={(selectedDate) => handleDateChange(selectedDate[0])} 
      onClose={(selectedDate) => handleDateChange(selectedDate[0])} 
      options={options}
    />
  );
  }
}