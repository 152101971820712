import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SystemSettingsScreen from './SystemSettingsScreen';


const Stack = createNativeStackNavigator();

export default function AssetsScreen ({ navigation }: { navigation: any }) {
    return (
        <Stack.Navigator>
          <Stack.Screen name="settings" component={SystemSettingsScreen} options={{ headerShown: false, title: 'Tickets' }} />
        </Stack.Navigator>
      )
}