import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export class User {
  id: string;
  name: string;
}

export interface UserState {
  user: User,
  token: string | null,

}

const initialState: UserState = {
  user: null,
  token: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      if (action.payload) {
        state.token = action.payload.token;
      }
    },
  }
})

export const { setUser} = userSlice.actions;

export default userSlice.reducer;