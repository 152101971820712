import { store } from '..';
import { authApi } from './api/auth-api';
import { ServiceResponse } from './service-response';

export class UserService {
  public login = (email, password) : Promise<ServiceResponse> => {
    return new Promise(async (resolve, reject) => {
      var result = await store.dispatch(authApi.endpoints.login.initiate({ email: email, password: password })) as any;
      if (result.error) {
        resolve(ServiceResponse.throwError());
        return;
      }
      resolve(ServiceResponse.success(result.data));
    });
  }
}