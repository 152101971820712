import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, SafeAreaView } from "react-native";
import { EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { TextInput, Button } from "react-native-paper";
import { useGetPinQuery } from "../../../../store/services/api/systemSettings-api";

export default function PinScreen({ navigation, route }) {
    const asset = useSelector((state: RootState) => state.asset);
    const [pinInput, setPinInput] = useState("");
    const [error, setError] = useState("");
    const { data } = useGetPinQuery(null);
    const correctPin = data?.value;

    const { nextScreen, uniqueRef } = route.params;

    const verifyPin = () => {
        if (pinInput === correctPin) {
            navigation.navigate(nextScreen, { uniqueRef });
            setError("");
        } else {
            setError("Incorrect Pin. Please try again.");
        }
    };

    return (
        <SafeAreaView style={GlobalStyle.mobile__page__container}>
            <View style={styles.header}>
                <TouchableOpacity
                    style={GlobalStyle.sub__title__icon}
                    onPress={() =>
                        navigation.navigate("asset", { uniqueRef: asset.uniqueReference })
                    }
                >
                    <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
                </TouchableOpacity>
            </View>
            <View style={styles.viewContent}>
                <View style={styles.section}>
                    <Text style={styles.disclaimerText}>Administration{'\n'}access only</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Enter Admin Pin</Text>
                    <TextInput
                        mode="outlined"
                        secureTextEntry
                        value={pinInput}
                        onChangeText={setPinInput}
                        style={styles.input}
                    />
                    {error ? <Text style={styles.errorText}>{error}</Text> : null}
                </View>
                <Button mode="contained" onPress={verifyPin} style={styles.button}>
                    <Text style={styles.buttonText}>Verify PIN</Text>
                </Button>
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    viewContent: {
        flexGrow: 1,
        justifyContent: "center",
        width: "100%",
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 20,
        width: "100%",
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
    },
    input: {
        flex: 1,
    },
    section: {
        width: "90%",
        height: "auto",
        alignItems: "stretch",
        alignSelf: 'center',
        marginBottom: 20,
        paddingHorizontal: "5%",
    },
    button: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#2C89C6",
        paddingVertical: 15,
        paddingHorizontal: "5%",
        marginVertical: 10,
        borderRadius: 5,
        width: "80%",
        height: "auto",
        justifyContent: "center",
        alignSelf: "center",
    },
    errorText: {
        color: "red",
        marginTop: 4,
        marginLeft: 4,
    },
    buttonText: {
        color: "white",
        marginLeft: 10,
        fontSize: 20
    },
    disclaimerText: {
        fontSize: 28,
        alignSelf: 'center',
        marginBottom: 20,
        textAlign: 'center',
    },
});